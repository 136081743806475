.divContainer {
  background-image: url("/public/assets/contact-us-bg.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
}
.divContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.divContainer > div > h1 {
  font-size: 50px;
  color: #faa61c;
  text-align: center;
}
.divContainer > div > h2 {
  color: whitesmoke;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 425px) {
  .divContainer > div {
    padding: 10px;
  }
}