.bannerContainer {
  background-image: url('/public/assets/about-bg.png');
  padding: 20px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-position: center;
}

.bannerHeading {
  font-size: 36px;
  margin-bottom: 20px;
  color: #faa61c;
}

.bannerPara {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: lighter;
}

.bannerBtn {
  font-size: 20px !important;
  padding: 12px 40px !important;
  border-radius: 30px !important;
  color: whitesmoke !important;
  border: 2px solid #faa61c !important;
  text-decoration: none !important;
  transition: all 0.3s ease !important;
}

.bannerBtn:hover {
  background-color: #faa61c !important;
  color: whitesmoke !important;
}

/* Responsive Styles */
@media (min-width: 600px) and (max-width: 1075px) {
  .bannerContainer {
    padding: 20px 10px;
  }

  .bannerHeading {
    font-size: 24px;
  }

  .bannerPara {
    font-size: 16px;
  }

  .bannerBtn {
    font-size: 16px !important;
    padding: 10px 30px !important;
  }
}

@media (max-width: 425px) {
  .bannerHeading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .bannerPara {
    font-size: 14px;
    margin-bottom: 6px;
  }
  .bannerBtn {
    font-size: 16px !important;
    padding: 5px 15px !important;
  }
}
