.divContainer {
  background-image: url("/public/assets/homebg5.WebP");
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.img {
  height: auto;
  width: 100%;
  border-radius: 15px;
  transition: all 0.2s;
}
.homeTextContainer > div {
  padding-top: 1.6em;
  width: 100%;
  color: whitesmoke;
  padding-left: 30px;
}
.homeTextContainer > div > h1 {
  color: #faa61c;
}
.homeTextContainer > div > p:nth-of-type(1) {
  font-size: 18px;
}

.homeTextContainer > div > p:nth-of-type(2) {
  font-size: 16px;
}
/* .homeTextContainer > div > h1 > span {
  font-size: 50px;
} */
.whyOttixhowHeading {
  margin-top: 8px;
  font-size: 20px;
}
.whyOttixhowimgCont {
  display: flex;
  padding: 5px;
  margin-top: 0.75em;
}
.learnBtn {
  color: white !important;
  background-color: #05224a !important;
  border-radius: 5em !important;
  border-color: #05224a !important;
  padding: 2px 10px 2px 10px !important;
}
.heading {
  color: #faa61c;
}
.cardStyle {
  padding: 15px !important;
  border: none !important;
}
.cardDesc {
  margin-top: 25px;
  color: #7a7e83;
}


/* starting of video player */
.videoWrapper {
  transform: translate(40%, 0%);
  cursor: pointer;
  margin-left: 1em;
}
.videoMain {
  position: relative;
  display: inline-block;
}
.video {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #05224a;
  z-index: 999;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}

.waves {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #09336e;
  opacity: 0;
  border-radius: 100%;
  right: -25px;
  bottom: -25px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}
.wave1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.wave2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.wave3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
/* ending the video player */

@media (max-width: 768px) {
  .homeTextContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homeTextContainer > div > h1 {
    font-size: 25px;
  }
  .homeTextContainer > div > p:nth-of-type(1) {
    font-size: 15px;
  }
  .homeTextContainer > div > p:nth-of-type(2) {
    font-size: 13px;
  }
  .homeTextContainer > div > h1 > span {
    font-size: 35px;
  }
}

@media (max-width: 425px) {
  .heading {
    font-size: 17px;
  }
  .toggleContainer span {
    font-size: 13px;
  }
}
@media (max-width: 326px) {
  .toggleContainer span {
    font-size: 10px;
  }
  .toggleContainer span {
    letter-spacing: 1px;
  }
}
