* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

@font-face {
  font-family: "Imported";
  src: url("../src/fonts/D-DIN.ttf") format("truetype");
}

body {
  overflow-x: hidden;
  font-family: Imported !important;
}
html {
  scroll-behavior: smooth;
}
