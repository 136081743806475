/* starting the slider buttons */
.toggleCheckbox {
  display: none;
}

.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 3px solid #204f81;
  border-radius: 30px;
  background: #204f81;
  font-weight: bold;
  color: #204f81;
  cursor: pointer;
  padding: 4px;
}

.toggleContainer::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 20px;
  background: white;
  transition: all 0.3s;
}

.toggleContainer span {
  padding: 6px;
  text-align: center;
  z-index: 1;
  font-size: 15px;
}

.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}

.toggleCheckbox:checked + .toggleContainer span:first-child {
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer span:last-child {
  color: #343434;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer span:first-child {
  color: #343434;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer span:last-child {
  color: white;
  transition: color 0.3s;
}
/* ending the slider buttons */