.img {
  height: auto;
  width: 100%;
  border-radius: 15px;
  height: auto;
  width: 100%;
  transition: all 0.2s; /* Add a transition for all properties with a 0.3s duration */
}
.colPadding {
  padding: 37px;
  padding-right: 37px !important;
}
.colPadding > h5 {
  letter-spacing: 2px;
}
.colPadding > h2 {
  color: #faa61c;
  font-size: 24px;
}
.colPadding > p {
  font-size: 17px;
  color: #7a7e83;
}

@media (max-width: 768px) {
  .colPadding {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 600px) {
  .img {
    padding: 10px;
  }
}
