.const {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  background: none;
  width: 160px;
  transition: 0.3s; /* Smooth transition for the background on hover */
}

.const:hover {
  background: #faa71c; /* New background color on hover */
}

.iconstyle {
  color: #faa71c;
  font-size: 35px;
  transition: color 0.3s; /* Smooth transition for the icon color on hover */
}

.const:hover .iconstyle {
  animation: zoomIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  color: white;
  transition: color 0.3s;
}
@keyframes zoomIn {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}
