.newcard {
  display: flex;
  justify-content: flex-start;
  padding: 22px;
  height: auto;
  word-wrap: break-word;
}
.newcard:hover {
  -webkit-box-shadow: 0 1px 10px 2px #dddddd;
  -moz-box-shadow: 0 1px 10px 2px #dddddd;
  box-shadow: 0 1px 10px 2px #dddddd;
}