.img-wrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.img-container {
  position: relative;
  overflow: hidden;
}
.inner-img {
  transition: transform 0.3s;
  object-fit: cover;
}
.img-wrapper:hover .inner-img {
  transform: scale(1.1);
}
.img-wrapper-card-body:hover .inner-img {
  transform: scale(1.1);
}
