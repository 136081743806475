.divContainer {
  background-image: url("/public/assets/saleshome.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
}
.divContainer > div > h1 {
  font-size: 50px;
  color: #faa61c;
  text-align: center;
}
.divContainer > div > h2 {
  font-size: 18px;
  color: whitesmoke;
  text-align: center;
}
@media (max-width: 425px) {
  .divContainer > div {
    padding: 10px;
  }
}