.divContainer {
  background-image: url("/public/assets/competitorhome.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
}
.divContainer > div > h1 {
  font-size: 50px;
  color: #faa61c;
  text-align: center;
}
.divContainer > div > h2 {
  font-size: 18px;
  color: whitesmoke;
  text-align: center;
}
.cardStyle {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  background-color: #fff;
  text-align: center;
  height: 100% !important;
  flex-direction: column;
}

.iconStyle {
  font-size: 2rem;
  color: #faa61c;
  margin-bottom: 1rem;
}
.titleStyle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #0b76b4;
}
.descriptionStyle {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 425px) {
  .divContainer > div {
    padding: 10px;
  }
}
