.divContainer {
  background-image: url("/public/assets/about-bg.png");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
}
.divContainer > div > h1 {
  font-size: 50px;
  color: #faa61c;
  text-align: center;
}
