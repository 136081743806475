.cursor {
  cursor: pointer;
}
.logoimg {
  max-height: 42px;
}
.navlink {
  text-decoration: none;
  color: white;
  font-size: 17px;
}
.navlink:hover {
  color: #faa71c !important;
}
.toggleIconend {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dropdownNav {
  font-size: 17px !important;
  background-color: whitesmoke !important;
  min-width: 10em !important;
  padding: 7px !important;
  padding-bottom: 0px !important;
}
.dropdownNav a {
  color: black;
  text-decoration: none;
  font-size: 15px;
}
.active {
  color: #faa71c !important;
}
.navBackground {
  background-color: transparent;
  color: white;
}
.scrolled {
  background-color: black;
  color: white;
  transition: background-color 0.4s;
}
