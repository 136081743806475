.divContainer {
  background-image: url("/public/assets/resourcehome1.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
}
.divContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 34px;
}
.divContainer > div > h1 {
  color: #faa61c;
  font-size: 50px;
  text-align: center;
}
.divContainer > div > h6 {
  color: whitesmoke;
  font-size: 18px;
  text-align: center;
}
.more-info {
  display: flex;
  flex-direction: row;
  padding: 12px;
}

.more-info h4 {
  color: #222739;
  margin-left: 17px;
  font-size: 20px;
  cursor: pointer;
}

.more-info svg {
  transition: transform 0.2s ease-in-out;
}

.more-info:hover svg {
  transform: translateX(15px);
}
.videolink {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease;
}

.videolink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}

.videolink:hover::before {
  background-color: rgba(79, 78, 78, 0.5);
}
.followbtn {
  background-color: #faa71c;
  border: none;
  border-radius: 27px;
  width: 150px;
  padding: 10px;
  color: whitesmoke;
  transition: background-color 0.3s ease;
}
.followbtn:hover {
  background-color: #036fb9;
}

.iframeContiner {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: -3em;
}

@media (max-width: 425px) {
  .divContainer > div > h6 {
    padding: 0 10px 0 10px;
  }
}
