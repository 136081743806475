.divContainer {
  background-image: url("/public/assets/about-bg.png");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
}
.divContainer > div > h1 {
  font-size: 50px;
  color: #faa61c;
}
.divContainer > div > h2 {
  color: whitesmoke;
  font-size: 18px;
  text-align: center;
}
.newcard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
  height: auto;
  margin-bottom: 17px;
  word-wrap: break-word;
  -webkit-box-shadow: 0 1px 10px 2px #dddddd;
  -moz-box-shadow: 0 1px 10px 2px #dddddd;
  box-shadow: 0 1px 10px 2px #dddddd;
}

.cardIcon {
  font-size: 50px;
  color: #0676b3;
  margin-left: -7px;
}
.tableIcon {
  font-size: 20px;
  color: #0676b3;
}

.navitemlink a {
  cursor: pointer;
  text-decoration: none;
  color: #e78610;
}

/* video section */
.videoPlayerContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.circlesContainer {
  position: relative;
  width: 330px;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoplayerOuterCircle {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 2px solid #204f81;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.videoplayerInnerCircle {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ccc;
  position: relative;
  z-index: 2;
}

.threedee {
  color: white;
  padding: 0.5em;
  /* transform: perspective(600px) rotateY(-15deg); */
}
.threedee img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}
.threedee img {
  width: 100%;
  height: auto;
}

/* responsive section */
@media screen and (max-width: 768px) {
  .videoPlayerContainer {
    justify-content: center;
  }
  .circlesContainer {
    width: 280px;
    height: 280px;
  }
  .videoplayerOuterCircle {
    width: 300px;
    height: 300px;
  }
  .videoplayerInnerCircle {
    width: 280px;
    height: 280px;
  }
  .videoPlayerContainer {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .videoPlayerContainer {
    justify-content: center;
    align-items: center;
  }
  .circlesContainer {
    width: 280px;
    height: 280px;
  }
  .videoplayerOuterCircle {
    width: 300px;
    height: 300px;
  }
  .videoplayerInnerCircle {
    width: 280px;
    height: 280px;
  }
}

@media screen and (max-width: 319px) {
  .circlesContainer {
    width: calc(40vw);
    height: calc(40vw);
  }
  .videoplayerOuterCircle {
    width: calc(50vw);
    height: calc(50vw);
  }
  .videoplayerInnerCircle {
    width: calc(40vw);
    height: calc(40vw);
  }
  .waveeffect {
    height: calc(20vw) !important;
    width: calc(20vw) !important;
  }
}

@keyframes waveAnimation {
  0% {
    box-shadow: 0 0 0 0px #204f81;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 2, 2, 0);
  }
  100% {
    box-shadow: 0 0 0 40px rgba(244, 4, 4, 0);
  }
}

.waveeffect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #204f81;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: #204f81;
  animation: waveAnimation 2s infinite ease-in-out;
  cursor: pointer;
}

@media (max-width: 425px) {
  .divContainer > div {
    padding: 10px;
  }
}
